import React from 'react';
import logo from './jms-logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <p>
          Joe Max Solutions' site under construction.
        </p>
      </header>
      <footer className="App-footer">
        <p>Copyright (C) {new Date().getFullYear()} Joe Max Solutions</p>
      </footer>
    </div>
  );
}

export default App;
